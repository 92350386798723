var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('top-menu',{attrs:{"menu":_vm.menu,"value":1,"cancel-button-text":"Отменить","action-button-text":"Сохранить","loading":_vm.loading},on:{"cancelbutton":function($event){return _vm.toRoute('/dashboard')},"actionbutton":function($event){}}}),_c('v-row',[_c('v-col',[_c('div',{staticStyle:{"position":"relative","height":"100%","margin":"-10px -35px"}},[_c('yandex-map',{staticClass:"map",attrs:{"id":"map1","zoom":_vm.zoom,"coords":_vm.coords,"scroll-zoom":true,"init-without-markers":true,"controls":['zoomControl']},on:{"click":function($event){return _vm.setMarker($event)}}},[_vm._l((_vm.shops),function(item,idx){return _c('ymap-marker',{key:idx,attrs:{"marker-id":idx,"coords":[item.lat, item.lng],"icon":{
              layout: 'default#imageWithContent',
              imageHref: require('@/assets/svg/Bottom-tail.svg'),
              imageSize: [150, 55],
              imageOffset: [-75, -50],
              content: _vm.getMarkerName(item.name) ,
              contentOffset: [0, 0],
              contentLayout: '<div class=classMarker>$[properties.iconContent]</div>',
            },"balloon":{
              header: item.name,
              body:
                ("<div>Адрес: " + (item.address) + "</div>\n                     <div>Телефон: " + (item.phone) + "</div>"),
              footer: ''
            }}})}),(_vm.shopIndex === -1)?_c('ymap-marker',{attrs:{"marker-id":_vm.shops.length,"coords":_vm.editedShop.coords,"icon":{
              layout: 'default#imageWithContent',
              imageHref: require('@/assets/svg/Bottom-tail.svg'),
              imageSize: [150, 55],
              imageOffset: [-75, -50],
              content: _vm.getMarkerName(_vm.editedShop.name) ,
              contentOffset: [0, 0],
              contentLayout: '<div class=classMarker>$[properties.iconContent]</div>',
            },"balloon":{
              header: _vm.editedShop.name,
              body:
                ("<div>Адрес: " + (_vm.editedShop.address) + "</div>\n                     <div>Телефон: " + (_vm.editedShop.phone) + "</div>"),
              footer: ''
            }}}):_vm._e()],2)],1)]),_c('v-col',[_c('div',{staticStyle:{"margin":"0 68px"}},[_c('div',{staticClass:"shop-title title-m-bold"},[_vm._v(" Точки продаж ")]),_c('div',{staticClass:"shop-description body-m-regular"},[_vm._v(" Если у вас несколько точек продаж - внесите их контактные данные и режимы работы, чтобы клиенты могли связаться с конкретным магазином и уточненить свои вопросы. ")]),(_vm.shopsCount >= 5)?_c('v-row',{staticClass:"shop-search",attrs:{"align":"center"}},[_c('v-col',[_c('v-text-field',{attrs:{"placeholder":"Поиск по названию, городу, улице","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.shopSearch),callback:function ($$v) {_vm.shopSearch=$$v},expression:"shopSearch"}},[_c('template',{slot:"prepend-inner"},[_c('span',{staticClass:"iconify",attrs:{"data-icon":"gg:search","data-inline":"false"}})])],2)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticStyle:{"padding":"0 !important"},attrs:{"color":"primary","text":true},on:{"click":function($event){return _vm.addShop()}}},[_c('v-icon',{staticStyle:{"margin-right":"5px"}},[_vm._v(" $iconify_feather-plus-circle ")]),_vm._v(" Добавить ")],1)],1)],1):_vm._e(),_vm._l((_vm.shops),function(item,i){return _c('div',{key:i},[_c('shop-item',{attrs:{"item":item,"index":i}})],1)}),(_vm.shopIndex === -1)?_c('div',{staticStyle:{"margin":"36px 0 0 0"}},[_c('shop-form',{ref:"shopForm",on:{"close":function($event){_vm.shopIndex = -2}}})],1):_c('div',{staticStyle:{"margin":"36px 0 68px 0"}},[_c('v-btn',{staticStyle:{"padding":"0 !important"},attrs:{"color":"primary","text":true},on:{"click":function($event){return _vm.addShop()}}},[_c('v-icon',{staticStyle:{"margin-right":"5px"}},[_vm._v(" $iconify_feather-plus-circle ")]),_vm._v(" Добавить точку продаж ")],1)],1)],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }