<template>
  <div>
    <top-menu
      :menu="menu"
      :value="1"
      cancel-button-text="Отменить"
      action-button-text="Сохранить"
      :loading="loading"
      @cancelbutton="toRoute('/dashboard')"
      @actionbutton=""
    />
    <v-row>
      <v-col>
        <div style="position: relative; height: 100%; margin: -10px -35px;">
          <yandex-map
            id="map1"
            :zoom="zoom"
            class="map"
            :coords="coords"
            :scroll-zoom="true"
            :init-without-markers="true"
            :controls="['zoomControl']"
            @click="setMarker($event)"
          >
            <ymap-marker
              v-for="(item, idx) in shops"
              :key="idx"
              :marker-id="idx"
              :coords="[item.lat, item.lng]"
              :icon="{
                layout: 'default#imageWithContent',
                imageHref: require('@/assets/svg/Bottom-tail.svg'),
                imageSize: [150, 55],
                imageOffset: [-75, -50],
                content: getMarkerName(item.name) ,
                contentOffset: [0, 0],
                contentLayout: '<div class=classMarker>$[properties.iconContent]</div>',
              }"
              :balloon="{
                header: item.name,
                body:
                  `<div>Адрес: ${item.address}</div>
                       <div>Телефон: ${item.phone}</div>`,
                footer: ''
              }"
            />
            <ymap-marker
              v-if="shopIndex === -1"
              :marker-id="shops.length"
              :coords="editedShop.coords"
              :icon="{
                layout: 'default#imageWithContent',
                imageHref: require('@/assets/svg/Bottom-tail.svg'),
                imageSize: [150, 55],
                imageOffset: [-75, -50],
                content: getMarkerName(editedShop.name) ,
                contentOffset: [0, 0],
                contentLayout: '<div class=classMarker>$[properties.iconContent]</div>',
              }"
              :balloon="{
                header: editedShop.name,
                body:
                  `<div>Адрес: ${editedShop.address}</div>
                       <div>Телефон: ${editedShop.phone}</div>`,
                footer: ''
              }"
            />
          </yandex-map>
        </div>
      </v-col>
      <v-col>
        <div style="margin: 0 68px;">
          <div class="shop-title title-m-bold">
            Точки продаж
          </div>
          <div class="shop-description body-m-regular">
            Если у вас несколько точек продаж - внесите их контактные данные и режимы работы, чтобы клиенты могли связаться с конкретным магазином и уточненить свои вопросы.
          </div>

          <v-row
            v-if="shopsCount >= 5"
            class="shop-search"
            align="center"
          >
            <v-col>
              <v-text-field
                v-model="shopSearch"
                placeholder="Поиск по названию, городу, улице"
                outlined
                hide-details
                clearable
              >
                <template slot="prepend-inner">
                  <span
                    class="iconify"
                    data-icon="gg:search"
                    data-inline="false"
                  />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="primary"
                :text="true"
                style="padding: 0 !important;"
                @click="addShop()"
              >
                <v-icon style="margin-right: 5px;">
                  $iconify_feather-plus-circle
                </v-icon>
                Добавить
              </v-btn>
            </v-col>
          </v-row>

          <!--
          МАГАЗИНЫ
          -->

          <div
            v-for="(item, i) in shops"
            :key="i"
          >
            <shop-item
              :item="item"
              :index="i"
            />
          </div>

          <!--
          МАГАЗИН ФОРМА
          -->

          <div
            v-if="shopIndex === -1"

            style="margin: 36px 0 0 0;"
          >
            <shop-form
              ref="shopForm"
              @close="shopIndex = -2"
            />
          </div>

          <div
            v-else
            style="margin: 36px 0 68px 0;"
          >
            <v-btn
              color="primary"
              :text="true"
              style="padding: 0 !important;"
              @click="addShop()"
            >
              <v-icon style="margin-right: 5px;">
                $iconify_feather-plus-circle
              </v-icon>
              Добавить точку продаж
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import ShopItem from '@/views/dashboard/form_component/ShopItem'
  import ShopForm from '@/views/dashboard/form_component/ShopForm'
  import ApiService from '@/api/api-client'
  // import { yandexMap, ymapMarker } from 'vue-yandex-maps'
  import { mask } from 'vue-the-mask'
  import TopMenu from '@/components/base/TopMenu'
  import Routing from '@/mixins/routing'

  export default {
    components: {
      // yandexMap, ymapMarker,
      TopMenu,
      ShopItem,
      ShopForm,
    },
    directives: { mask },
    mixins: [Routing],
    data () {
      return {
        // settings: {
        //   apiKey: 'e994d83e-a10e-47e4-bb45-94038d17ba64',
        //   lang: 'ru_RU',
        //   coordorder: 'latlong',
        //   version: '2.1',
        // },
        zoom: 16,
        //
        loading: false,
        openCreateForm: false,
        resultAdr: '',
        addresses: [],
        searchCity: '',
        searchString: '',
        // markerIcon: {
        //   layout: 'default#imageWithContent',
        //   content: '123 v12',
        //   contentOffset: [0, 15],
        //   contentLayout: '<div class="ymapMarker">$[properties.iconContent]</div>',
        // },
        actionsShow: false,
        markerIcon: {
          layout: 'default#imageWithContent',
          imageHref: require('@/assets/svg/Bottom-tail.svg'),
          imageSize: [150, 55],
          imageOffset: [-75, -50],
          content: 'Магазин на Чекистов 312317',
          contentOffset: [0, 0],
          contentLayout: '<div class="classMarker" style="display: flex; align-self: center; align-content: center; justify-content:center; width: 150px; height: 50px; color: #FFFFFF; font-weight: bold; text-align: center; line-height: 50px">$[properties.iconContent]</div>',
        },
        shop: { lat: '', lng: '' },
        newShop: {
          name: '',
          city: '',
          address: '',
          phone: '',
          coords: [],
          lat: '',
          lng: '',
          timezone: '',
          workTimes: [
            {
              startTime: '',
              endTime: '',
              days: [],
              breakStart: '',
              breakEnd: '',
            },
          ],
        },
        newWorkTime: [],
        newBreakTime: [],
        cardBg: require('@/assets/svg/Shine.svg'),
        items: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
        days: [
          { id: 0, shortName: 'ПН', fullName: 'Понедельник' },
          { id: 1, shortName: 'ВТ', fullName: 'Вторник' },
          { id: 2, shortName: 'СР', fullName: 'Среда' },
          { id: 3, shortName: 'ЧТ', fullName: 'Четверг' },
          { id: 4, shortName: 'ПТ', fullName: 'Пятница' },
          { id: 5, shortName: 'СБ', fullName: 'Суббота' },
          { id: 6, shortName: 'ВС', fullName: 'Воскресенье' },
        ],
        rules: {
          required: value => !!value || this.$t('required'),
          counter: value => value.length <= 20 || 'Max 20 characters',
        },
        filtered_addr: [],
        shopSearch: null,
      }
    },
    computed: {
      menu () {
        return this.$store.getters['company/program/menu']
      },
      programModel () {
        return this.$store.getters['company/program/programModel']
      },
      shopsCount () {
        return this.$store.getters['company/program/shops'].length
      },
      shops () {
        let shops = []
        if (this.shopSearch) {
          const search = String(this.shopSearch).toLocaleLowerCase()
          shops = this.$store.getters['company/program/shops'].filter(item => {
            if (item.name && item.name.toLocaleLowerCase().indexOf(search) !== -1) return item
            if (item.address && item.address.toLocaleLowerCase().indexOf(search) !== -1) return item
          })
        } else {
          shops = this.$store.getters['company/program/shops']
        }
        // мапинг рабочего времени
        shops.forEach(item => {
          item.workTimes = this.getWorkTime(item.worktime_json)
          item.coords = [item.lat, item.lng]
        })
        return shops
      },
      merchant_id () {
        return JSON.parse(localStorage.getItem('vue-session-key')).merchant_id
      },
      editedShop: {
        get () {
          return this.$store.getters['company/program/editedShop']
        },
        set (v) {
          this.$store.commit('company/program/SET_EDITED_SHOP', v)
        },
      },
      shopIndex: {
        get () {
          return this.$store.getters['company/program/shopIndex']
        },
        set (v) {
          this.$store.commit('company/program/SET_SHOP_INDEX', v)
        },
      },
      coords: {
        get () {
          return this.$store.getters['company/program/mapCenter']
        },
        set (v) {
          this.$store.commit('company/program/SET_MAP_CENTER', v)
        },
      },
      fullAddress: {
        get () {
          return this.$store.getters['company/program/fullAddress']
        },
        set (v) {
          this.$store.commit('company/program/SET_FULL_ADDRESS', v)
        },
      },
      addressErrors: {
        get () {
          return this.$store.getters['company/program/addressErrors']
        },
        set (v) {
          this.$store.commit('company/program/SET_ADDRESS_ERRORS', v)
        },
      },
    },
    watch: {
      shopIndex (v) {
        if (v >= 0) {
          const shop = this.shops.find((item, i) => i === v)
          if (shop) {
            this.coords = [shop.lat ? shop.lat : "", shop.lng ? shop.lng : ""]
          }
        }
      },
    },
    created () {
      // центрируемся на первой торговой точке
      if (this.shops.length) this.coords = [this.shops[0].lat, this.shops[0].lng]
    },
    methods: {
      getMarkerName (str) {
        if (!str) return ''
        const maxLen = 16
        const strTrim = str.trim()
        return strTrim.length > maxLen ? strTrim.substring(0, maxLen) + '...' : strTrim
      },
      async setMarker (e) {
        // не делаем запрос если
        // не открыта карточка торговой точки
        if (this.shopIndex !== -2) {
          this.coords = e.get('coords')
          const queryCoords = this.coords[0] + ', ' + this.coords[1]
          const success = await ApiService.get(
            `https://nominatim.openstreetmap.org/search?q=${queryCoords}&format=json&addressdetails=1`,
          ) // Добавить валидацию
          console.log("RESULT", success)
          
          if (success && success[0]){
            console.log("POINT TEXT", success[0].display_name.split(", "))
            //this.editedShop.city_id = city ? city.name : null
            const address = success[0].address
            // Страна, область, город, улица, дом
            if (this.editedShop.autoCoords)
              this.editedShop.address = (address.country ? address.country + ", " : ", ") + 
                                        (address.state ? address.state + ", " : ", ") +
                                        (address.city ? address.city + ", " : ", ") +
                                        (address.road ? address.road + ", " : ", ") +
                                        (address.house_number ? address.house_number : "")
            this.editedShop.lat = this.coords[0]
            this.editedShop.lng = this.coords[1]
            this.editedShop.coords = this.coords
            this.editedShop.city_id = address.city
            this.fullAddress = true
            // обновление координат при редактировании магазина
            if (this.shopIndex >= 0) {
                this.shops.forEach((item, i) => {
                  if (i === this.shopIndex) {
                    item.lat = this.coords[0]
                    item.lng = this.coords[1]
                  }
                })
              }
          }
          // массив геообъектов
          /*const featureMembers = success.response.GeoObjectCollection.featureMember

          if (featureMembers.length) {
            const featureMember = featureMembers[0]
            const kind = featureMember.GeoObject.metaDataProperty.GeocoderMetaData.kind
            const geoAddress = featureMember.GeoObject.metaDataProperty.GeocoderMetaData.text
            let city = null
            city = featureMember.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(item => item.kind === 'locality')
            if (!city) city = featureMember.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(item => item.kind === 'area')
            const address = (kind === 'house' || !this.editedShop.address) ? geoAddress : this.editedShop.address
            // if (kind === 'house' ) {
            if (address) {
              this.fullAddress = true
              this.addressErrors = []
            } else {
              this.fullAddress = false
              this.addressErrors = ['Введите полный адрес']
            }
            console.log(kind, city, address)

            this.editedShop.city_id = city ? city.name : null
            this.editedShop.address = address
            this.editedShop.lat = this.coords[0]
            this.editedShop.lng = this.coords[1]
            this.editedShop.coords = this.coords

            // обновление координат при редактировании магазина
            if (this.shopIndex >= 0) {
              this.shops.forEach((item, i) => {
                if (i === this.shopIndex) {
                  item.lat = this.coords[0]
                  item.lng = this.coords[1]
                }
              })
            }
          }*/
        }
      },
      goToPrevent () {
        if (this.currentStep <= 1) {
          this.$router.push('/dashboard')
        } else this.currentStep -= 1
      },
      updateShop (shop) {
        this.newShop = shop
        this.newShopEdit = true
        // this.deleteShop(shop)
      },
      generate (pos) {
        const item = this.addresses.find(
          item => item.GeoObject.Point.pos === pos,
        )
        const city = this.cities.find(
          item => item.id === this.newShop.city,
        )
        this.resultAdr = city.name + ', ' + item.GeoObject.name
        // console.log('generate item', item)
        const coordinates = item.GeoObject.Point.pos.split(' ')
        this.newShop.coords = [coordinates[1], coordinates[0]]
        this.coords = this.newShop.coords
      },
      addWorkTime () {
        if (this.newShop.workTimes.length === 7) {
          return false
        } else {
          this.newShop.workTimes.push(
            {
              startTime: '',
              endTime: '',
              days: [],
              breakStart: '',
              breakEnd: '',
            },
          )
        }
      },
      getWorkTime (workObj) {
        const days = {
          mondey: 0,
          tuesday: 1,
          wednesday: 2,
          thursday: 3,
          friday: 4,
          saturday: 5,
          sunday: 6,
        }

        const wtNew = []

        for (const key in workObj) {
          if (!workObj[key].isWorkDay) continue

          const item = {
            startTime: workObj[key].start,
            endTime: workObj[key].finish,
            breakStart: workObj[key].pause_start,
            breakEnd: workObj[key].pause_finish,
          }

          const itemExist = this.$_.findWhere(wtNew, item)
          if (itemExist) {
            itemExist.days.push(days[key])
          } else {
            item.days = [days[key]]
            wtNew.push(item)
          }
        }

        return wtNew
      },
      getWorkTimeOld (workObj) {
        const days = {
          mondey: 0,
          tuesday: 1,
          wednesday: 2,
          thursday: 3,
          friday: 4,
          saturday: 5,
          sunday: 6,
        }

        const wtNew = []

        for (const key in workObj) {
          // заполнение первого элемента
          if (!wtNew.length) {
            if (workObj[key].isWorkDay) {
              wtNew.push({
                days: [days[key]],
                startTime: workObj[key].start,
                endTime: workObj[key].finish,
                breakStart: workObj[key].pause_start,
                breakEnd: workObj[key].pause_finish,
              })
            }
          } else {
            wtNew.forEach(period => {
              if (workObj[key].isWorkDay &&
                period.startTime === workObj[key].start &&
                period.endTime === workObj[key].finish &&
                period.breakStart === workObj[key].pause_start &&
                period.breakEnd === workObj[key].pause_finish) {
                if (Array.isArray(period.days)) {
                  period.days.push(days[key])
                } else {
                  period.days = []
                  period.days.push(days[key])
                }
              } else {
                if (workObj[key].isWorkDay) {
                  wtNew.push({
                    days: [days[key]],
                    startTime: workObj[key].start,
                    endTime: workObj[key].finish,
                    breakStart: workObj[key].pause_start,
                    breakEnd: workObj[key].pause_finish,
                  })
                }
              }
            })
          }
        }

        return wtNew
      },
      addShop () {
        this.$store.dispatch('company/program/GenNewShop').then(newShop => {
          console.log('GenNewShop', newShop)
          this.editedShop = newShop
          this.shopIndex = -1
          this.$nextTick(() => { if (this.$refs.shopForm) this.$vuetify.goTo(this.$refs.shopForm) })
        })
      },
    },
  }
</script>

<style lang="scss">
.classMarker {
  display: flex;
  align-self: center;
  align-content: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: #FFFFFF;
  font-weight: bold;
  text-align: center;
  line-height: 50px;

  .ymapMarker {
    width: 150px;
    height: 50px;
    background: #4776E6;
    border-radius: 8px;
    opacity: .3;
  }
}
[class*="copyrights-pane"] {
  display: none !important;
}
</style>

<style lang="scss" scoped>
@import 'form-update.scss';
</style>
